import "./infocard.scss";
import "./infocard_splide.scss"

import React from "react";

interface InfoCardProps {
  title: string;
  subtitle: string;
  image: string;
  children?: React.ReactNode;
  fa_icon?: string;
  starting?: boolean;
  isInSplide?: boolean;
}

class InfoCard extends React.PureComponent<InfoCardProps> {
  
  myref: React.RefObject<unknown>;
  isInSplide: boolean;

  constructor(props: InfoCardProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.myref = React.createRef();
    this.isInSplide = props.isInSplide ? props.isInSplide : false;  // default value is false
  }

  handleClick = (e: any) => {

    if (this.isInSplide) {
      return;
    }

    let target = e.currentTarget as HTMLDivElement
    if (target.classList.contains("active") || target.parentElement === null) {
      return;
    }
    Array.from(target.parentElement.children).forEach((option) => {
      option.classList.remove("active");
    });
    target.classList.add("active");

    this.setSubtitle(target, this.props.subtitle);
  };

  componentDidMount(): void {
    if (this.props.starting) {
      this.setSubtitle(this.myref.current, this.props.subtitle);
    }
  }

  setSubtitle = (target, subtitle: string) => {
    // get parent
    let parent = target.parentElement?.parentElement?.parentElement?.parentElement;
    (parent?.getElementsByClassName("section-subheader")[0] as HTMLDivElement).textContent = subtitle;
  }

  render() {
    const { title, subtitle, children, image, fa_icon } = this.props;

    return (
      <div
        className="option"
        style={{backgroundImage: `url(${image})`}}
        onClick={this.handleClick}
        ref={this.myref}
      >
        <div className="shadow"></div>
        <div className="shadow shadow2"></div>

        <div className="label">
          <div className="icon">
            <i className={fa_icon}></i>
          </div>
          <div className="info">
            <div className="main">{title}</div>
            {/* <div className="sub">{subtitle}</div> */}
          </div>
        </div>
        <div className="desc">
          {children}
        </div>
      </div>
    );
  }
}

export default InfoCard;
