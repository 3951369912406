import "./colors.scss";
import "./vars.scss";
import "./App.scss";
import "./style/section/section1.scss";
import "./style/section/section2.scss";
import "./style/section/section3.scss";
import "./style/section/section4.scss";
import "./style/section/section5.scss";
import "./style/section/section6.scss";
import "./style/section/contact.scss";
import "./style/section/footer.scss";

import React from "react";
import sr from "./ScrollReveal.js";
import { createRoot } from "react-dom/client";

import MyNavbar from "./components/navbar/myNavbar.tsx";
import Section from "./components/section/section.tsx";
import Marquee from "./components/marquee/marquee.tsx";
import * as Profiles from "./components/profile/profile.tsx";
import * as Entry from "./components/story/entry.tsx";
import LinkedInPost from "./components/linkedin/linkedin.tsx";

import InfoCard from "./components/infocard/infocard.tsx";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import * as Images from "./Images.js";
import * as Videos from "./Videos.js";
import ImageSW from "./components/switcher/image/image.tsx";

import {
  RevoBITsLogoTransparentsLight,
  RevoBITsLogoTransparentsDark,
} from "./Images.js";

interface AppProps {}

interface AppState {
  theme: string;
  current_prob_subtitle: string;
  current_sol_subtitle: string;
}

const cors_config: RequestInit = {
  mode: "cors",
  credentials: "same-origin",
  headers: {
    "Access-Control-Allow-Credentials": "true",
    "access-control-allow-origin": "https://mysiteurl.com",
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
  },
  method: "GET",
};

class App extends React.Component<AppProps, AppState> {
  prob_subtitles = [
    "Printing human tissue models is not just stacking layers of cells – obviously.",
    "Things change over time – and bioinks even faster.",
    "It’s not just Copy & Paste — unfortunately.",
  ];

  sol_subtitles = [
    "Mixing bioinks without compromising biology – seamlessly.",
    "Printing the biomaterials you need – successfully.",
    "Printing your models, the way you intended – reliably.",
  ];

  constructor(props) {
    super(props);
    this.state = {
      theme: "dark",
      current_prob_subtitle: this.prob_subtitles[0],
      current_sol_subtitle: this.sol_subtitles[0],
    };
    this.toggleTheme = this.toggleTheme.bind(this);
  }

  toggleTheme = () => {
    const app = document.querySelector(".app") as HTMLElement;
    const theme = this.state.theme === "light" ? "dark" : "light";
    app.dataset.theme = theme;

    this.setState({ theme });
    document.documentElement.setAttribute("data-theme", theme);
  };

  showMorePosts() {
    const container = document.getElementById(
      "linkedin-container"
    ) as HTMLElement;
    const posts = container.getElementsByClassName("linkedin-post");

    const increment = 1;
    let i = 0;
    while (posts[i] !== undefined && !posts[i].classList.contains("hidden")) {
      i++;
    }

    if (i + increment - 1 < posts.length) {
      for (let j = 0; j < increment; j++) {
        posts[i + j].classList.remove("hidden");
      }
    }

    if (i + increment >= posts.length) {
      (
        document.getElementById("load-more-button") as HTMLElement
      ).classList.add("hidden");
    }
  }

  updateStoryEntries = () => {
    const wrapper = document.getElementsByClassName(
      "story-wrapper-desktop"
    )[0] as HTMLElement;
    const story_entries = Array.from(
      wrapper.getElementsByClassName("story-entry")
    ) as HTMLElement[];
    const bobbles = Array.from(
      wrapper.getElementsByClassName("story-entry-bobble")
    ) as HTMLElement[];

    let tops_left: number[] = [];
    let tops_right: number[] = [];
    let i = 0 as any;
    let tops = 0;
    const min_dist = 70; // min distance to prev entry on same side
    for (i in story_entries) {
      let entry = story_entries[i];

      if (i == 0) {
        tops_right.push(tops);
      } else if (i == 1) {
        let prev_entry = story_entries[i - 1];
        tops = prev_entry.getBoundingClientRect().height * 0.5;
        tops_left.push(tops);
      } else {
        let prev_entry = story_entries[i - 1];
        let prev_prev_entry = story_entries[i - 2];

        // last tops are defined because i >= 2 and therefore tops_left and tops_right have at least 1 entry
        const last_r = tops_right.at(-1) as number;
        const last_l = tops_left.at(-1) as number;
        if (i % 2 == 0) {
          let min_tops_l =
            last_r + prev_prev_entry.getBoundingClientRect().height + min_dist; // min min_dist distance to prev entry on same side
          let min_tops_r =
            last_l + prev_entry.getBoundingClientRect().height / 2; // min 50px distance to prev entry on same side
          tops = Math.max(min_tops_l, min_tops_r);
          tops_right.push(tops);
        } else {
          let min_tops_l =
            last_l + prev_prev_entry.getBoundingClientRect().height + min_dist; // min 50px distance to prev entry on same side
          let min_tops_r =
            last_r + prev_entry.getBoundingClientRect().height / 2; // min 50px distance to prev entry on same side
          tops = Math.max(min_tops_l, min_tops_r);
          tops_left.push(tops);
        }
      }

      entry.style.top = tops + "px";
      bobbles[i].style.top =
        tops +
        entry
          .getElementsByClassName("story-entry-date-title")[0]
          .getBoundingClientRect().height /
          2 -
        bobbles[i].getBoundingClientRect().height / 2 +
        "px";
    }

    const new_height = -(
      story_entries[0].getBoundingClientRect().top -
      story_entries[i].getBoundingClientRect().bottom
    );
    wrapper.style.height = new_height + "px";
    const divider = wrapper.getElementsByClassName("divider")[0] as HTMLElement;
    divider.style.height = new_height + "px";
  };

  setActiveOption = () => {
    const section3 = document.getElementById("section3") as HTMLElement;
    const section4 = document.getElementById("section4") as HTMLElement;
    section3.querySelectorAll(".option")[0].classList.add("active");
    section4.querySelectorAll(".option")[0].classList.add("active");
  };

  renderLinkedInPosts = () => {
    fetch(process.env.PUBLIC_URL + "/linkedin/list.txt", cors_config)
      .then((r) => r.text())
      .then((text) => {
        // console.log(text);
        const entries = text.split("\n");
        // split every entry by empty space
        const parsed = entries.map((entry) => {
          return entry.split(" ");
        });

        // render all posts but hide all after the first 3
        const post = entries.map((entry, index) => {
          const linkedinpost = (
            <LinkedInPost
              hidden={index > 2}
              post_id={entry}
              path={process.env.PUBLIC_URL + "/linkedin/" + entry}
            />
          );
          return linkedinpost;
        });

        const container = createRoot(
          document.getElementById("linkedin-container") as HTMLElement
        );
        container.render(post);
      });
  };

  componentDidMount() {
    const section1 = document.getElementById("section1") as HTMLElement;
    const parallax1 = section1.getElementsByClassName(
      "section-background"
    )[0] as HTMLElement;
    const off1 = 0;
    parallax1.style.backgroundPositionY = 0 * 0.7 + off1 + "px";
    // Parallax Effect for BG 1
    window.addEventListener("scroll", function () {
      let offset = window.scrollY;
      parallax1.style.backgroundPositionY = offset * 0.7 + off1 + "px";
    });

    function playVid(el: any) {
      el.play();
    }
    function pauseVid(el: any) {
      el.pause();
    }

    sr.reveal("video", {
      beforeReveal: playVid,
      beforeReset: pauseVid,
      // reset: true,
    });

    // const section5 = document.getElementById("section5") as HTMLElement;
    // const parallax5 = section5.getElementsByClassName(
    //   "section-background"
    // )[0] as HTMLElement;
    // const off5 = 0;
    // parallax5.style.backgroundPositionY = 0 * 0.7 + off5 + "px";
    // // Parallax Effect for BG 5
    // window.addEventListener("scroll", function () {
    //   let offset = window.scrollY;
    //   parallax5.style.backgroundPositionY = offset * 0.7 + off5 + "px";
    // });

    this.renderLinkedInPosts();

    this.updateStoryEntries();
    window.addEventListener("resize", this.updateStoryEntries);

    this.setActiveOption();

    // scroll reveal
    const config_left = {
      origin: "left",
      duration: 1000,
      delay: 150,
      distance: "500px",
      scale: 1,
      easing: "ease",
    };

    const config_right = {
      origin: "right",
      duration: 1000,
      delay: 150,
      distance: "500px",
      scale: 1,
      easing: "ease",
    };

    sr.reveal(".__sr_right", config_right);
    sr.reveal(".__sr_left", config_left);

    sr.reveal(".__sr_right.__700", { ...config_right, delay: 700 });
    sr.reveal(".__sr_right.__1300", { ...config_right, delay: 1300 });
    sr.reveal(".__sr_right.__1900", { ...config_right, delay: 1900 });

    // this.prob1.current?.addEventListener("moved", () => {
    //   console.log("prob1 active");
    // });
  }

  changeProbSubtitleOnSplideMove = (splide: any, newIndex: string | number) => {
    this.setState({ current_prob_subtitle: this.prob_subtitles[newIndex] });
  };

  changeSolSubtitleOnSplideMove = (splide: any, newIndex: string | number) => {
    this.setState({ current_sol_subtitle: this.sol_subtitles[newIndex] });
  };

  render() {
    const { theme } = this.state;

    return (
      <div className="app" data-theme={theme}>
        <MyNavbar
          theme={theme}
          toggleTheme={this.toggleTheme}
          isChecked={this.state.theme === "light"}
        />

        {/* SECTIONS */}

        {/*******************************************************************/}
        {/* SECTION 1 */}
        {/*******************************************************************/}

        <Section id="section1" backgroundImage={Images.bg_section1}>
          <div id="section1-content-wrapper">
            <div className="section-subheader claim">
              <span>
                {/* <span className="orbitron revoclaim">RevoBITs:</span> */}
                <span className="__sr_right orbitron revoclaim">
                  Revolutionizing Bioprinting
                </span>
              </span>
              <span>
                <span className="__sr_right __700 orbitron bitbybit">BIT</span>
                <span className="__sr_right __1300 orbitron bitbybit-white">
                  by
                </span>
                <span className="__sr_right __1900 orbitron bitbybit">BIT</span>
              </span>
            </div>
            <div id="section1-content">
              <div className="wrapper">
                <div className="left __sr_left">
                  <p>
                    RevoBITs was born from the desire to make bioprinting more
                    valuable for everyone. We enable medical research and
                    pharmaceutical industries to develop and standardize the
                    production of meaningful human tissue models by providing
                    the first smart multimaterial bioprinter.
                  </p>
                  <p>
                    Our vision is to reduce and eventually replace animal tests
                    in research and drug development with our upcoming{" "}
                    <span></span>
                    <span className="emph pink">Revo</span>
                    <span className="emph">lutionary</span>{" "}
                    <span className="emph blue">Bi</span>
                    <span className="emph">ofabrication</span>{" "}
                    <span className="emph blue">T</span>
                    <span className="emph">echnologie</span>
                    <span className="emph blue">s</span>.
                  </p>
                  <p>
                    For now, we are a startup at the Institute of Polymer
                    Materials at the Friedrich-Alexander-Universität
                    Erlangen-Nürnberg (FAU). This provides access to leading
                    experts across diverse research fields and equips us with
                    the essential machinery required to manufacture our
                    prototype.
                  </p>
                  <p>
                    Learn more about RevoBITs, a German startup —
                    Revolutionizing Bioprinting Bit by Bit
                  </p>
                </div>
                <div className="right __sr_right">
                  <ImageSW
                    customClassName="revo-bits-logo"
                    theme={theme}
                    image_light={RevoBITsLogoTransparentsLight}
                    image_dark={RevoBITsLogoTransparentsDark}
                  />
                  {/* <img
                    src="https://revo-bits.com/static/media/RevoBITsLogoTransparentsDark.c2bbaeb217d0ac604956.png"
                    alt="RevoBITs Logo"
                  /> */}
                </div>
              </div>
            </div>

            <p className="section-subsubheader" id="fundedby-header">
              Supported and funded by:
            </p>
            <Marquee theme={this.state.theme}></Marquee>
          </div>
        </Section>

        {/*******************************************************************/}
        {/* SECTION 2 */}
        {/*******************************************************************/}
        <Section id="section2" backgroundVideo={Videos.network}>
          <div id="section2-content-wrapper">
            <p className="section-header">The Team</p>
            <div id="section2-content">
              <div className="profile-wrapper">
                <Profiles.Profile
                  animName="__sr_left"
                  theme={this.state.theme}
                  mail="ruben@revo-bits.de"
                  img={Images.Ruben_Profile_Img}
                  name="Ruben Gerrit Scheuring"
                  position="Hardware"
                  description={Profiles.ruben_desc}
                  hover="Please contact me for questions regarding hardware and bioprinting collaborations."
                  linkedin="https://www.linkedin.com/in/ruben-gerrit-scheuring-4170a7126/"
                />
                <Profiles.Profile
                  animName="__sr_right"
                  theme={this.state.theme}
                  mail="stefan@revo-bits.de"
                  img={Images.Stefan_Profile_Img}
                  name="Stefan Schrüfer"
                  position="Electronics"
                  description={Profiles.stefan_desc}
                  hover="Please contact me for questions regarding electronics and process control."
                  linkedin="https://www.linkedin.com/in/stefan-schr%C3%BCfer-707367161/"
                />
                <Profiles.Profile
                  animName="__sr_left"
                  theme={this.state.theme}
                  mail="janik@revo-bits.de"
                  img={Images.Janik_Profile_Img}
                  name="Janik Altenhöfer"
                  position="Operations"
                  description={Profiles.janik_desc}
                  hover="Please contact me for questions regarding business development and investment opportunities."
                  linkedin="https://www.linkedin.com/in/janik-altenhoefer-86b9a9179/"
                />
                <Profiles.Profile
                  animName="__sr_right"
                  theme={this.state.theme}
                  mail="alex@revo-bits.de"
                  img={Images.Alex_Profile_Img}
                  name="Alexander Björk"
                  position="Software"
                  description={Profiles.alex_desc}
                  hover="Please contact me for questions regarding software solutions for bioprinting."
                  linkedin="https://www.linkedin.com/in/alexander-bj%C3%B6rk-2998a4172/"
                />
              </div>
            </div>
          </div>
        </Section>

        {/*******************************************************************/}
        {/* SECTION 3 */}
        {/*******************************************************************/}
        <Section backgroundImage={Images.bg_section3} id="section3">
          <div id="section3-content-wrapper">
            <p className="section-header">
              Problems and Challenges in Bioprinting
            </p>
            <p className="section-subheader quote-italic">
              {this.state.current_prob_subtitle}
            </p>
            <div id="section3-content">
              <div className="infocard-wrapper">
                <div className="options">
                  <InfoCard
                    fa_icon="fas fa-project-diagram"
                    title="Problem 1: High Biological Complexity"
                    subtitle="Printing human tissue models is not just stacking layers of cells – obviously."
                    image={Images.problem1_heart}
                    starting={true}
                  >
                    <p>
                      The heart, for instance, relies on the interaction of over
                      60 different cell populations and a variety of local
                      matrix properties for its functionality. Modern
                      bioprinters can only process up to 6 defined bioinks,
                      which cannot be mixed due to their complex material
                      properties and technological limitations.
                    </p>
                  </InfoCard>
                  <InfoCard
                    fa_icon="fa-solid fa-microscope"
                    title="Problem 2: Variable and Unstable Biomaterials"
                    subtitle="Things change over time – and bioinks even faster."
                    image={Images.problem2_fluctuations}
                  >
                    <p>
                      Unlike conventional 3D printers, bioprinters work with
                      living cells and biomaterials that alter their properties
                      based on a wide range of environmental factors. This
                      impairs the predictability of the outcome, significantly
                      constraining the potential of the bioprinting field.
                    </p>
                  </InfoCard>

                  <InfoCard
                    fa_icon="fa-solid fa-person"
                    title="Problem 3: Printing Process is not reproducible"
                    subtitle="It’s not just Copy & Paste — unfortunately."
                    image={Images.problem3_scientists}
                  >
                    <p>
                      Similar to other fields of threedimensional printing,
                      every material needs optimal manufacturing parameters to
                      produce the desired outcome repeatably. The combination of
                      unstable biomaterials and additive manufacturing limits
                      the potential to print meaningful human tissue models in
                      necessary scale.
                    </p>
                  </InfoCard>
                </div>
              </div>

              <Splide
                aria-label="Problems and Challenges in Bioprinting"
                onMoved={(splide, newIndex) => {
                  // console.log(splide.root.children[1].children[0].children[1].children[0], newIndex);
                  this.changeProbSubtitleOnSplideMove(splide, newIndex);
                }}
              >
                <SplideSlide>
                  <InfoCard
                    fa_icon="fas fa-project-diagram"
                    title="Problem 1: High Biological Complexity"
                    subtitle="Printing human tissue models is not just stacking layers of cells – obviously."
                    image={Images.problem1_heart}
                    isInSplide={true}
                  >
                    <p>
                      The heart, for instance, relies on the interaction of over
                      60 different cell populations and a variety of local
                      matrix properties for its functionality. Modern
                      bioprinters can only process up to 6 defined bioinks,
                      which cannot be mixed due to their complex material
                      properties and technological limitations.
                    </p>
                  </InfoCard>
                </SplideSlide>
                <SplideSlide>
                  <InfoCard
                    fa_icon="fa-solid fa-microscope"
                    title="Problem 2: Variable and Unstable Biomaterials"
                    subtitle="Things change over time – and bioinks even faster."
                    image={Images.problem2_fluctuations}
                    isInSplide={true}
                  >
                    <p>
                      Unlike conventional 3D printers, bioprinters work with
                      living cells and biomaterials that alter their properties
                      based on a wide range of environmental factors. This
                      impairs the predictability of the outcome, significantly
                      constraining the potential of the bioprinting field.
                    </p>
                  </InfoCard>
                </SplideSlide>
                <SplideSlide>
                  <InfoCard
                    fa_icon="fa-solid fa-person"
                    title="Problem 3: Printing Process is not reproducible"
                    subtitle="It’s not just Copy & Paste — unfortunately."
                    image={Images.problem3_scientists}
                    isInSplide={true}
                  >
                    <p>
                      Similar to other fields of threedimensional printing,
                      every material needs optimal manufacturing parameters to
                      produce the desired outcome repeatably. The combination of
                      unstable biomaterials and additive manufacturing limits
                      the potential to print meaningful human tissue models in
                      necessary scale.
                    </p>
                  </InfoCard>
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </Section>

        {/*******************************************************************/}
        {/* SECTION 4 */}
        {/*******************************************************************/}
        <Section id="section4">
          <div id="section4-content-wrapper">
            <p className="section-header">RevoBITs Solution</p>
            <p className="section-subheader quote-italic">
              {this.state.current_sol_subtitle}
            </p>
            <div id="section4-content">
              <div className="infocard-wrapper">
                {/* <script src="https://codepen.io/z-/pen/a8e37caf2a04602ea5815e5acedab458.js"></script> */}
                <div className="options">
                  <InfoCard
                    fa_icon="fa-solid fa-droplet"
                    title="Solution 1: Single-Nozzle Multimaterial Printhead"
                    subtitle="Mixing bioinks without compromising biology – seamlessly."
                    image={Images.solution1_nozzle}
                    starting={true}
                  >
                    <p>
                      For the first time, RevoBITs enables the printing and on
                      demand mixing of temperature-sensitive, protein-based
                      bioinks to generate biologically relevant mechanical and
                      biochemical properties in the construct. This advancement
                      facilitates the development and fine-tuning of more
                      complex living tissue models.
                    </p>
                  </InfoCard>

                  <InfoCard
                    fa_icon="fa-solid fa-award"
                    title="Solution 2: In-Printer Material Characterization"
                    subtitle="Printing the biomaterials you need – successfully."
                    image={Images.solution2_dashboard}
                  >
                    <p>
                      Our patented analysis of elongational rheological
                      properties enables the prediction of both printability and
                      cell survival rates. Our technologies not only assure
                      verification of material quality, but also provide
                      scientific data to accelerate the development and
                      optimization of new bioinks.
                    </p>
                  </InfoCard>

                  <InfoCard
                    fa_icon="fa-solid fa-microchip"
                    title="Solution 3: Smart Process Control"
                    subtitle="Printing your models, the way you intended – reliably."
                    image={Images.solution3_intell}
                  >
                    <p>
                      Our sensor technology - applied for standardization and
                      material characterization - provides essential insights
                      into the printing process. This enables our bioprinter to
                      minimize undesired bioink changes in real-time by
                      adjusting relevant parameters, ensuring reproducible
                      material properties and process results.
                    </p>
                  </InfoCard>
                </div>
              </div>

              <Splide
                aria-label="RevoBITs Solutions for Hassle-Free Bioprinting"
                onMoved={(splide, newIndex) => {
                  // console.log(splide.root.children[1].children[0].children[1].children[0], newIndex);
                  this.changeSolSubtitleOnSplideMove(splide, newIndex);
                }}
              >
                <SplideSlide>
                  <InfoCard
                    fa_icon="fa-solid fa-droplet"
                    title="Solution 1: Single-Nozzle Multimaterial Printhead"
                    subtitle="Mixing bioinks without compromising biology – seamlessly."
                    image={Images.solution1_nozzle}
                    isInSplide={true}
                  >
                    <p>
                      For the first time, RevoBITs enables the printing and on
                      demand mixing of temperature-sensitive, protein-based
                      bioinks to generate biologically relevant mechanical and
                      biochemical properties in the construct. This advancement
                      facilitates the development and fine-tuning of more
                      complex living tissue models.
                    </p>
                  </InfoCard>
                </SplideSlide>
                <SplideSlide>
                  <InfoCard
                    fa_icon="fa-solid fa-award"
                    title="Solution 2: In-Printer Material Characterization"
                    subtitle="Printing the biomaterials you need – successfully."
                    image={Images.solution2_dashboard}
                    isInSplide={true}
                  >
                    <p>
                      Our patented analysis of elongational rheological
                      properties enables the prediction of both printability and
                      cell survival rates. Our technologies not only assure
                      verification of material quality, but also provide
                      scientific data to accelerate the development and
                      optimization of new bioinks.
                    </p>
                  </InfoCard>
                </SplideSlide>
                <SplideSlide>
                  <InfoCard
                    fa_icon="fa-solid fa-microchip"
                    title="Solution 3: Smart Process Control"
                    subtitle="Printing your models, the way you intended – reliably."
                    image={Images.solution3_intell}
                    isInSplide={true}
                  >
                    <p>
                      Our sensor technology - applied for standardization and
                      material characterization - provides essential insights
                      into the printing process. This enables our bioprinter to
                      minimize undesired bioink changes in real-time by
                      adjusting relevant parameters, ensuring reproducible
                      material properties and process results.
                    </p>
                  </InfoCard>
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </Section>

        {/*******************************************************************/}
        {/* SECTION 5 */}
        {/*******************************************************************/}
        <Section
          // backgroundImage={Images.bg_section5}
          backgroundVideo={Videos.smoke_in_water_2}
          id="section5"
        >
          <div id="section5-content-wrapper">
            <p className="section-header">Our Story</p>
            <div id="section5-content">
              <div className="story-wrapper-desktop">
                <Entry.EntryRight
                  animOnReveal={true}
                  date="2018"
                  title="Doctoral Studies"
                >
                  <p>
                    Ruben and Stefan start their doctoral studies in the
                    collaborative research center{" "}
                    <a
                      href="https://trr225biofab.de/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      TRR 225
                    </a>{" "}
                    "From the fundamentals of biofabrication towards functional
                    tissue models".
                  </p>
                </Entry.EntryRight>

                <Entry.EntryLeft
                  animOnReveal={true}
                  date="2019"
                  title="First Challenges"
                >
                  <p>
                    Both discover independently that existing bioprinters
                    hindered progress in the development and production of
                    meaningful 3D tissue models.
                  </p>
                </Entry.EntryLeft>

                <Entry.EntryRight
                  animOnReveal={true}
                  date="09/2020"
                  title="Forming & Storming"
                >
                  <p>
                    Ruben and Stefan join forces to develop a new bioprinting
                    platform. Together they successfully participate in{" "}
                    <a
                      href="https://www.existency.de/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      EXISTENCY’s
                    </a>{" "}
                    business program Reality Bites – Building Box, as well as in
                    BayStartUP’s{" "}
                    <a
                      href="https://www.baystartup.de/en/business-plan-competitions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Business Plan Competition
                    </a>{" "}
                    to validate their idea and to get initial feedback from
                    entrepreneurs and investors.
                  </p>
                </Entry.EntryRight>

                <Entry.EntryLeft
                  animOnReveal={true}
                  date="02/2021"
                  title="Janik Joines"
                >
                  <p>
                    With Janik on board, the team successfully expands and
                    acquires complementary expertise in the fields of
                    engineering and business development.
                  </p>
                </Entry.EntryLeft>
                <Entry.EntryRight
                  animOnReveal={true}
                  date="08/2021"
                  title="Alex Joines"
                >
                  <p>
                    While completing his master’s thesis under the supervision
                    of Ruben and Stefan, Alex demonstrates remarkable expertise,
                    leading to an invitation to join and complement the founder
                    team.
                  </p>
                </Entry.EntryRight>

                <Entry.EntryLeft
                  animOnReveal={true}
                  date="10/2021"
                  title="Corporate Identity"
                >
                  <p>
                    In cooperation with the{" "}
                    <a
                      href="https://pixel-campus.de/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Pixel Campus
                    </a>
                    , a logo and branding strategy is developed.
                  </p>
                </Entry.EntryLeft>

                <Entry.EntryRight
                  animOnReveal={true}
                  date="08/2022"
                  title="First Funding"
                >
                  <p>
                    The team successfully convinces the{" "}
                    <a
                      href="https://www.bayern-innovativ.de/en/validierungsfoerderung"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Bayern Innovativ
                    </a>{" "}
                    jury and secures €300,000 in funding to build the
                    demonstrator. This allows the whole team to focus solely on
                    the project for the very first time.
                  </p>
                </Entry.EntryRight>

                <Entry.EntryLeft
                  animOnReveal={true}
                  date="05/2023"
                  title="Top 5 Business Plan Competition"
                >
                  <p>
                    RevoBITs enhances the business plan and secures a top 5
                    place in the
                    <a
                      href="https://www.baystartup.de/en/startupdate/competition-updates/businessplan-wettbewerb-nordbayern-2023-die-gewinner-aus-phase-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Business Plan Competition
                    </a>
                    Northern Bavaria
                  </p>
                </Entry.EntryLeft>

                <Entry.EntryRight
                  animOnReveal={true}
                  date="09/2023"
                  title="BioM and SmiLe BioTech Bootcamp"
                >
                  <p>
                    RevoBITs is selected as one of 9 teams to further improve
                    biotech entrepreneurial knowledge, skills, and industry
                    connections.
                  </p>
                </Entry.EntryRight>

                <Entry.EntryLeft
                  animOnReveal={true}
                  date="10/2023"
                  title="BioM m4 Award"
                >
                  <p>
                    RevoBITs secures{" "}
                    <a
                      href="https://www.bio-m.org/en/news/news-detail/m4-award-2023-25-mio-euro-fuer-fuenf-forscherteams-aus-bayern.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      €500,000 in non-dilutive funding
                    </a>{" "}
                    for finishing the development of the bioprinter provided by
                    the Bavarian Ministry of Economic Affairs, Regional
                    Development and Energy.
                  </p>
                </Entry.EntryLeft>
                <Entry.EntryRight
                  animOnReveal={true}
                  date="12/2023"
                  title="Roche Audience Award"
                >
                  <p>
                    RevoBITs convinces the Munich biotech community and{" "}
                    <a
                      href="https://www.linkedin.com/posts/biom-gmbh_biotechbootcamp-demoday-genicity-activity-7138553751220494337-Ivrt/?utm_source=share&utm_medium=member_desktop"
                      target="_blank"
                      rel="noreferrer"
                    >
                      wins the Roche audience award
                    </a>{" "}
                    for the best pitch at the BioTech Bootcamp Demo Day.
                  </p>
                </Entry.EntryRight>
                <Entry.EntryLeft
                  animOnReveal={true}
                  date="02/2024"
                  title="Nucleate Activator Program"
                >
                  <p>
                    As one of only a few teams in Germany, RevoBITs was chosen
                    to{" "}
                    <a
                      href="https://www.linkedin.com/posts/revo-bits_nucleate-germany-activator-program-2024-launch-activity-7170788784987443200-HO-7?utm_source=share&utm_medium=member_desktop"
                      target="_blank"
                      rel="noreferrer"
                    >
                      participate in the prestigious Nucleate Activator
                    </a>{" "}
                    program for “next-generation bioentrepreneurs” to boost the
                    lab to market journey in 2024.
                  </p>
                </Entry.EntryLeft>
                <Entry.EntryRight
                  animOnReveal={true}
                  date="06/2024"
                  title="Nucleate Activator Main Prize"
                >
                  <p>
                    Concluding the 2024 Nucleate Activator demo day in Berlin,
                    RevoBITs prevails against the other 2024 batch startups. The
                    expert jury from the Berlin startup ecosystem hands the{" "}
                    <a
                      href="https://www.linkedin.com/posts/nucleategermany_activator-milestone-activator-activity-7212377613909037056-fBEY?utm_source=share&utm_medium=member_desktop"
                      target="_blank"
                      rel="noreferrer"
                    >
                      "2024 Activator Main Prize" for the best pitch to RevoBITs
                    </a>{" "}
                    in front of 200 biotech enthusiasts.
                  </p>
                </Entry.EntryRight>

                <div className="divider"></div>
                <Entry.Bobble skipAnim={true} />
                <Entry.Bobble skipAnim={true} />
                <Entry.Bobble skipAnim={true} />
                <Entry.Bobble skipAnim={true} />
                <Entry.Bobble skipAnim={true} />
                <Entry.Bobble skipAnim={true} />
                <Entry.Bobble skipAnim={true} />
                <Entry.Bobble skipAnim={true} />
                <Entry.Bobble skipAnim={true} />
                <Entry.Bobble skipAnim={true} />
                <Entry.Bobble skipAnim={true} />
                <Entry.Bobble skipAnim={true} />
                <Entry.Bobble skipAnim={true} />
              </div>

              <div className="story-wrapper-mobile">
                {/* <div className="mobile-divider"></div> */}
                <div className="mobile-view">
                  <Entry.EntryMobile
                    animOnReveal={true}
                    date="2018"
                    title="Doctoral Studies"
                  >
                    <p>
                      Ruben and Stefan start their doctoral studies in the
                      collaborative research center{" "}
                      <a
                        href="https://trr225biofab.de/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        TRR 225
                      </a>{" "}
                      "From the fundamentals of biofabrication towards
                      functional tissue models".
                    </p>
                  </Entry.EntryMobile>

                  <Entry.EntryMobile
                    animOnReveal={true}
                    date="2019"
                    title="First Challenges"
                  >
                    <p>
                      Both discover independently that existing bioprinters
                      hindered progress in the development and production of
                      meaningful 3D tissue models.
                    </p>
                  </Entry.EntryMobile>

                  <Entry.EntryMobile
                    animOnReveal={true}
                    date="09/2020"
                    title="Forming & Storming"
                  >
                    <p>
                      Ruben and Stefan join forces to develop a new bioprinting
                      platform. Together they successfully participate in{" "}
                      <a
                        href="https://www.existency.de/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        EXISTENCY’s
                      </a>{" "}
                      business program Reality Bites – Building Box, as well as
                      in BayStartUP’s{" "}
                      <a
                        href="https://www.baystartup.de/en/business-plan-competitions"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Business Plan Competition
                      </a>{" "}
                      to validate their idea and to get initial feedback from
                      entrepreneurs and investors.
                    </p>
                  </Entry.EntryMobile>

                  <Entry.EntryMobile
                    animOnReveal={true}
                    date="02/2021"
                    title="Janik Joines"
                  >
                    <p>
                      With Janik on board, the team successfully expands and
                      acquires complementary expertise in the fields of
                      engineering and business development.
                    </p>
                  </Entry.EntryMobile>

                  <Entry.EntryMobile
                    animOnReveal={true}
                    date="08/2021"
                    title="Alex Joines"
                  >
                    <p>
                      While completing his master’s thesis under the supervision
                      of Ruben and Stefan, Alex demonstrates remarkable
                      expertise, leading to an invitation to join and complement
                      the founder team.
                    </p>
                  </Entry.EntryMobile>

                  <Entry.EntryMobile
                    animOnReveal={true}
                    date="10/2021"
                    title="Corporate Identity"
                  >
                    <p>
                      In cooperation with the{" "}
                      <a
                        href="https://pixel-campus.de/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Pixel Campus
                      </a>
                      , a logo and branding strategy is developed.
                    </p>
                  </Entry.EntryMobile>

                  <Entry.EntryMobile
                    animOnReveal={true}
                    date="08/2022"
                    title="First Funding"
                  >
                    <p>
                      The team successfully convinces the{" "}
                      <a
                        href="https://www.bayern-innovativ.de/en/validierungsfoerderung"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Bayern Innovativ
                      </a>{" "}
                      jury and secures €300,000 in funding to build the
                      demonstrator. This allows the whole team to focus solely
                      on the project for the very first time.
                    </p>
                  </Entry.EntryMobile>

                  <Entry.EntryMobile
                    animOnReveal={true}
                    date="05/2023"
                    title="Top 5 Business Plan Competition"
                  >
                    <p>
                      RevoBITs enhances the business plan and secures a top 5
                      place in the
                      <a
                        href="https://www.baystartup.de/en/startupdate/competition-updates/businessplan-wettbewerb-nordbayern-2023-die-gewinner-aus-phase-2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Business Plan Competition
                      </a>
                      Northern Bavaria
                    </p>
                  </Entry.EntryMobile>

                  <Entry.EntryMobile
                    animOnReveal={true}
                    date="09/2023"
                    title=<span>
                      Bio<sup>M</sup> and SmiLe BioTech Bootcamp
                    </span>
                  >
                    <p>
                      RevoBITs is selected as one of 9 teams to further improve
                      biotech entrepreneurial knowledge, skills, and industry
                      connections.
                    </p>
                  </Entry.EntryMobile>

                  <Entry.EntryMobile
                    animOnReveal={true}
                    date="10/2023"
                    title=<span>
                      Bio<sup>M</sup> m<sup>4</sup> Award
                    </span>
                  >
                    <p>
                      RevoBITs secures{" "}
                      <a
                        href="https://www.bio-m.org/en/news/news-detail/m4-award-2023-25-mio-euro-fuer-fuenf-forscherteams-aus-bayern.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        €500,000 in non-dilutive funding
                      </a>{" "}
                      for finishing the development of the bioprinter provided
                      by the Bavarian Ministry of Economic Affairs, Regional
                      Development and Energy.
                    </p>
                  </Entry.EntryMobile>
                  <Entry.EntryMobile
                    animOnReveal={true}
                    date="12/2023"
                    title="Roche Audience Award"
                  >
                    <p>
                      RevoBITs convinces the Munich biotech community and{" "}
                      <a
                        href="https://www.linkedin.com/posts/biom-gmbh_biotechbootcamp-demoday-genicity-activity-7138553751220494337-Ivrt/?utm_source=share&utm_medium=member_desktop"
                        target="_blank"
                        rel="noreferrer"
                      >
                        wins the Roche audience award
                      </a>{" "}
                      for the best pitch at the BioTech Bootcamp Demo Day.
                    </p>
                  </Entry.EntryMobile>
                  <Entry.EntryMobile
                    animOnReveal={true}
                    date="02/2024"
                    title="Nucleate Activator Program"
                  >
                    <p>
                      As one of only a few teams in Germany, RevoBITs was chosen
                      to{" "}
                      <a
                        href="https://www.linkedin.com/posts/revo-bits_nucleate-germany-activator-program-2024-launch-activity-7170788784987443200-HO-7?utm_source=share&utm_medium=member_desktop"
                        target="_blank"
                        rel="noreferrer"
                      >
                        participate in the prestigious Nucleate Activator
                      </a>{" "}
                      program for “next-generation bioentrepreneurs” to boost
                      the lab to market journey in 2024.
                    </p>
                  </Entry.EntryMobile>
                  <Entry.EntryMobile
                    animOnReveal={true}
                    date="06/2024"
                    title="Nucleate Activator Main Prize"
                  >
                    <p>
                      Concluding the 2024 Nucleate Activator demo day in Berlin,
                      RevoBITs prevails against the other 2024 batch startups.
                      The expert jury from the Berlin startup ecosystem hands
                      the{" "}
                      <a
                        href="https://www.linkedin.com/posts/nucleategermany_activator-milestone-activator-activity-7212377613909037056-fBEY?utm_source=share&utm_medium=member_desktop"
                        target="_blank"
                        rel="noreferrer"
                      >
                        "2024 Activator Main Prize" for the best pitch to
                        RevoBITs
                      </a>{" "}
                      in front of 200 biotech enthusiasts.
                    </p>
                  </Entry.EntryMobile>
                </div>
              </div>
            </div>
          </div>
        </Section>

        {/*******************************************************************/}
        {/* SECTION 6 */}
        {/*******************************************************************/}
        <Section id="section6">
          <div className="news-wrapper">
            <p className="section-header">News</p>
            <div id="linkedin-container"></div>
            <button id="load-more-button" onClick={this.showMorePosts}>
              Load more...{" "}
            </button>
          </div>
        </Section>

        {/*******************************************************************/}
        {/* SECTION 7 */}
        {/*******************************************************************/}
        <Section backgroundImage={Images.bg_section7} id="section7">
          <div className="contact-wrapper">
            <h1>Contact Us</h1>
            <div className="contact">
              <div className="contact-item visit">
                <i className="fa-solid fa-location-dot"></i>
                <span className="contact-item-header">Visit</span>
                <p className="contact-item-content visit">
                  RevoBITs
                  <br />
                  FAU Erlangen-Nürnberg
                  <br />
                  Lehrstuhl für Polymerwerkstoffe
                  <br />
                  Martensstraße 7/1st floor, Room 191
                  <br />
                  91058 Erlangen
                  <br />
                  Germany
                </p>
              </div>
              <div className="contact-item phone">
                <i className="fa fa-phone"></i>
                <span className="contact-item-header phone">By Phone</span>
                <p className="contact-item-content phone">
                  <a href="tel:+4991318527733"> (+49) 9131 8527733 </a>
                  <span id="phone-time-reminder">
                    {" "}
                    (Monday to Friday, 9am to 4pm CET){" "}
                  </span>
                </p>
              </div>
              <div className="contact-item email">
                <i className="fa fa-envelope"></i>
                <span className="contact-item-header mail">By Mail</span>
                <p className="contact-item-content mail">
                  <a href="mailto:info@revo-bits.de">info@revo-bits.de</a>
                </p>
              </div>
            </div>
          </div>
        </Section>

        {/*******************************************************************/}
        {/* FOOTER */}
        {/*******************************************************************/}
        <div id="footer">
          <div className="footer-row">
            <div className="copyright-text-wrapper">
              <span className="copyright-text">
                Copyright &copy; 2024 All Rights Reserved by RevoBITs.
              </span>
            </div>
            <div className="impressum-text-wrapper">
              <span className="impressum-text">
                <a
                  href={process.env.PUBLIC_URL + "/impressum.html"}
                  target="_blank"
                >
                  Impressum
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
