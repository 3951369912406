import "./profile.scss";

import React from "react";

import ImageSW from "../switcher/image/image.tsx";

interface ProfileProps {
  theme?: string;
  img?: string;
  name?: string;
  position?: string;
  description?: string;
  hover?: string;
  mail?: string;
  animName?: string;
  linkedin?: string;
}

class Profile extends React.PureComponent<ProfileProps> {
  render() {
    const { theme, img, name, position, description, mail } = this.props;
    return (
      <div className="profile">
        <div className="img-overlay-container">
          <ImageSW
            image_light={img}
            image_dark={img}
            customClassName={"profile-img " + this.props.animName}
            theme={theme}
          />
          <a
            href={"mailto:" + mail}
            className={"overlay overlay-text " + this.props.animName}
          >
            <p>{this.props.hover}</p>
            <span>{mail}</span>
          </a>
        </div>
        <a
          className={"name-container " + this.props.animName}
          href={this.props.linkedin}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h1 className={"name"}>{name}</h1>
          <i className={"profile-linkedin-icon fa-brands fa-linkedin"} />
        </a>
        <div className={"cofounder-position-wrapper " + this.props.animName}>
          <h2 className="cofounder">Co-Founder</h2>
          <div className="divider">|</div>
          <h2 className="position">{position}</h2>
        </div>
        <p className={"description " + this.props.animName}>{description}</p>
        <div className="mail-flex-end"></div>
        <a
          className={"mail-wrapper " + this.props.animName}
          href={"mailto:" + mail}
        >
          <p>{this.props.hover}</p>
          <span>{mail}</span>
        </a>
      </div>
    );
  }
}

const ruben_desc =
  "is a trained molecular biologist and tissue engineer. As a research scientist at the University of Wuerzburg he developed bioprinting methods and hardware, as well as bioinks and protocols to boost their printability. At RevoBITs Ruben oversees all biofabrication related development activities, including printing, hardware, and methods alike.";
const stefan_desc =
  "is a material scientist and an expert in rheology of biomaterials. During his time as a scientist at FAU he gained extensive experience in extrusion based bioprinting, biomaterial characterization, and process analytics. His expertise in electronics and biomaterials lays the foundation for his responsibility of process control, as well as sensor integration at RevoBITs.";
const janik_desc =
  "is a trained industrial engineer, earning his master’s degree from FAU. He has worked in different positions and roles within development projects at large corporates like Daimler AG and LEONI. At RevoBITs he takes care of all operations including project management, business development, as well as the project financials.";
const alex_desc =
  'is a computer scientist who has completed his master\'s thesis on "Machine Vision in Bioprinting" in collaboration with RevoBITs. During his studies in Wuerzburg, he specialized in programming and gained experience working with industrial robots at the Chair of Robotics and Telematics. Now as a full-stack developer, he handles all software development for the bioprinter and oversees IT infrastructure.';

export { Profile, ruben_desc, stefan_desc, janik_desc, alex_desc };
