import React from "react";

import "./section.scss";

interface SectionProps {
  backgroundImage?: string;
  backgroundVideo?: string;
  children?: React.ReactNode;
  custom_className?: string;
  id?: string;
  outside_children?: React.ReactNode;
}

class Section extends React.PureComponent<SectionProps> {
  render() {
    const {
      backgroundImage,
      backgroundVideo,
      children,
      custom_className,
      id,
      outside_children,
    } = this.props;

    if (backgroundVideo !== undefined) {
      // console.log(backgroundVideo);
      return (
        <div className={["section", custom_className].join(" ")} id={id}>
          <div className="section-content-outside">{outside_children}</div>
          <video
            className="section-background video"
            // autoPlay
            muted
            loop
            playsInline
            src={backgroundVideo}
          ></video>
          <div className="section-overlay video"></div>
          <div className="section-content">{children}</div>
        </div>
      );
    }

    return (
      <div className={["section", custom_className].join(" ")} id={id}>
        <div className="section-content-outside">{outside_children}</div>
        <div
          className="section-background"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
        {/* <div className="section-background"></div> */}

        <div className="section-overlay"></div>
        <div className="section-content">{children}</div>
      </div>
    );
  }
}

export default Section;
