import React from "react";
// import { Image } from 'react-bootstrap';

import "./image.scss";

interface ImageProps {
  image_light?: string;
  image_dark?: string;
  theme?: string;
  customClassName: string;
  my_ref?: any;
}

class ImageSW extends React.Component<ImageProps> {

  render() {
    const { image_light, image_dark, theme, customClassName, my_ref } = this.props;

    // console.log("ImageSW: ", theme);

    return (
      <>
        {theme === "light" ? (
          <img src={image_light} className={customClassName} ref={my_ref} />
        ) : (
          <img src={image_dark} className={customClassName} ref={my_ref} />
        )}
      </>
    );
  }
}

export default ImageSW;
