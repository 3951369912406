import React from "react";
import "./marquee.scss";

import ImageSW from "../../components/switcher/image/image.tsx";

import Existency_Logo from "../../assets/support/Existency-Logoset-final_EXISTENCY-Logo-Farbverlauf-05-e1610645868417.png";
import BioM_Logo from "../../assets/support/biom.png";
import BayStartup_Logo from "../../assets/support/BayStartUP_Logo_RGB_NEU.svg";
import Bayern_Logo from "../../assets/support/BayerStaatsministerium-fuer-Wirtschaft-Landesentwicklg_Logo_335px.png";
import BayernInnovativ_Logo from "../../assets/support/bayern-innovativ-logo-mit-claim.png";
import LSP_FAU_LOGO_NEW_DARK from "../../assets/support/fau_lsp_logo_dark.png";
import LSP_FAU_LOGO_NEW_LIGHT from "../../assets/support/fau_lsp_logo_light.png";
import NUCLEATE_LOGO from "../../assets/support/Nucleate-Logotype-RGB-Positive.png";
import SBMC_LOGO from "../../assets/support/Logo_SBMC.png";

interface MarqueeProps {
  theme?: string;
}

class Marquee extends React.PureComponent<MarqueeProps> {
  handleMouseOver = () => {
    // find all element with class Marquee-content and add class paused
    const marqueeContent = document.querySelectorAll(".Marquee-content");
    marqueeContent.forEach((element) => {
      element.classList.add("paused");
    });
  };

  handleMouseLeave = () => {
    // find all element with class Marquee-content and add class paused
    const marqueeContent = document.querySelectorAll(".Marquee-content");
    marqueeContent.forEach((element) => {
      element.classList.remove("paused");
    });
  };

  render() {
    const { theme } = this.props;

    // List of Elements to be displayed in the marquee
    const marqueeContent: JSX.Element[] = [];
    marqueeContent.push(
      <a
        href="https://www.existency.de/"
        target="_blank"
        rel="noreferrer"
        className="Marquee-tag"
        key={"Existency"}
      >
        <ImageSW
          image_light={Existency_Logo}
          image_dark={Existency_Logo}
          customClassName="Marquee-logo"
          theme={theme}
        />
      </a>
    );
    marqueeContent.push(
      <a
        href="https://www.lsp.tf.fau.eu/"
        target="_blank"
        rel="noreferrer"
        className="Marquee-tag"
        key={"FAU"}
      >
        <ImageSW
          image_light={LSP_FAU_LOGO_NEW_DARK}
          image_dark={LSP_FAU_LOGO_NEW_LIGHT}
          customClassName="Marquee-logo"
          theme={theme}
        />
      </a>
    );

    marqueeContent.push(
      <a
        href="https://www.bio-m.org/en.html"
        target="_blank"
        rel="noreferrer"
        className="Marquee-tag"
        key={"BioM"}
      >
        <ImageSW
          image_light={BioM_Logo}
          image_dark={BioM_Logo}
          customClassName="Marquee-logo biom-logo"
          theme={theme}
        />
      </a>
    );

    marqueeContent.push(
      <a
        href="https://www.baystartup.de/en/"
        target="_blank"
        rel="noreferrer"
        className="Marquee-tag"
        key={"BayStartup"}
      >
        <ImageSW
          image_light={BayStartup_Logo}
          image_dark={BayStartup_Logo}
          customClassName="Marquee-logo"
          theme={theme}
        />
      </a>
    );

    marqueeContent.push(
      <a
        href="https://www.stmwi.bayern.de/english/"
        target="_blank"
        rel="noreferrer"
        className="Marquee-tag"
        key={"Bayern"}
      >
        <ImageSW
          image_light={Bayern_Logo}
          image_dark={Bayern_Logo}
          customClassName="Marquee-logo"
          theme={theme}
        />
      </a>
    );

    marqueeContent.push(
      <a
        href="https://www.bayern-innovativ.de/en"
        target="_blank"
        rel="noreferrer"
        className="Marquee-tag"
        key={"BayernInnovativ"}
      >
        <ImageSW
          image_light={BayernInnovativ_Logo}
          image_dark={BayernInnovativ_Logo}
          customClassName="Marquee-logo"
          theme={theme}
        />
      </a>
    );

    marqueeContent.push(
      <a
        href="https://www.nucleate.xyz/locations/germany/"
        target="_blank"
        rel="noreferrer"
        className="Marquee-tag"
        key={"NucleateGermany"}
      >
        <ImageSW
          image_light={NUCLEATE_LOGO}
          image_dark={NUCLEATE_LOGO}
          customClassName="Marquee-logo scale-60"
          theme={theme}
        />
      </a>
    );

    marqueeContent.push(
      <a
        href="https://smartbiomaterials.nl/"
        target="_blank"
        rel="noreferrer"
        className="Marquee-tag"
        key={"SBMC"}
      >
        <ImageSW
          image_light={SBMC_LOGO}
          image_dark={SBMC_LOGO}
          customClassName="Marquee-logo scale-60"
          theme={theme}
        />
      </a>
    );

    return (
      <div
        className="Marquee"
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="Marquee-content">{marqueeContent}</div>
        <div className="Marquee-content">{marqueeContent}</div>
      </div>
    );
  }
}

export default Marquee;
