import "./linkedin.scss";

import React from "react";

interface LinkedInPostProps {
  path: string;
  post_id: string;
  hidden?: boolean;
}

interface LinkedInPostState {
  hidden: boolean;
  text_path: string;
  org_link: string;
  info_path: string;
  company_image_path: string;
  num_images: number;
  text: string;
}

const cors_config = {
  mode: "cors",
  credentials: "same-origin",
  headers: {
    "Access-Control-Allow-Credentials": "true",
    "access-control-allow-origin": "https://mysiteurl.com",
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
  },
  method: "GET",
};

class LinkedInPost extends React.PureComponent<
  LinkedInPostProps,
  LinkedInPostState
> {
  posts: any;
  containerRef = React.createRef<HTMLUListElement>();

  constructor(props: LinkedInPostProps) {
    super(props);
    this.state = {
      hidden: false,
      text_path: this.props.path.split(" ")[0] + "/text.txt",
      org_link:
        "https://www.linkedin.com/posts/" +
        this.props.path
          .split(" ")[0]
          .split("" + process.env.PUBLIC_URL + "/linkedin/")[1],
      info_path: this.props.path.split(" ")[0] + "/info.txt",
      company_image_path: this.props.path.split(" ")[0] + "/company_img.jpg",
      num_images: parseInt(this.props.path.split(" ")[1]),
      text: "Dummy",
    };
  }

  getOrgLink = () => {
    return (
      "https://www.linkedin.com/posts/" +
      this.props.path
        .split(" ")[0]
        .split("" + process.env.PUBLIC_URL + "/linkedin/")[1]
    );
  };

  componentDidMount(): void {
    fetch(this.state.text_path, cors_config)
      .then((r) => r.text())
      .then((res) => {
        this.setState({ text: res });
        // text = res;
        const ele = document.getElementById(this.props.post_id) as HTMLElement;
        ele.getElementsByClassName("linkedin-post-body-text")[0].innerHTML =
          this.state.text;
      });

    fetch(this.state.info_path, cors_config)
      .then((r) => r.text())
      .then((res) => {
        const info = res.split(" ");
        const ele = document.getElementById(this.props.post_id) as HTMLElement;
        ele.getElementsByClassName(
          "linkedin-post-header-left-text-name"
        )[0].innerHTML = info[0];
        ele.getElementsByClassName(
          "linkedin-post-header-left-text-follower"
        )[0].innerHTML = info[1] + " " + info[2];
        ele.getElementsByClassName(
          "linkedin-post-header-left-text-time"
        )[0].innerHTML = info[3];
      });

    if (this.state.num_images > 0) {
      this.posts = (
        <>
          {Array.from({ length: this.state.num_images }, (_, i) => (
            <li key={i}>
              <img
                src={this.props.path.split(" ")[0] + "/img" + i + ".jpg"}
                alt=""
              ></img>
            </li>
          ))}
        </>
      );
    } else {
      if (this.containerRef.current) {
        // console.log("No images");
        this.containerRef.current.style.display = "none";
      }
    }
  }

  render() {
    return (
      <a
        href={this.getOrgLink()}
        className="no-highlight"
        style={{ textDecoration: "none" }}
        rel="noreferrer"
        target="_blank"
      >
        <div
          className={"linkedin-post" + (this.props.hidden ? " hidden" : "")}
          id={this.props.post_id}
        >
          <div className="linkedin-post-header">
            <div className="linkedin-post-header-left">
              <div className="linkedin-post-header-left-image">
                <img src={this.state.company_image_path} alt=""></img>
              </div>
              <div className="linkedin-post-header-left-text">
                <div className="linkedin-post-header-left-text-name">
                  Loading...
                </div>
                <div className="linkedin-post-header-left-text-follower">
                  Loading...
                </div>
                <div className="linkedin-post-header-left-text-time">
                  Loading...
                </div>
              </div>
            </div>
          </div>
          <div className="linkedin-post-body">
            <p className="linkedin-post-body-text display-linebreak">
              {this.state.text}
            </p>
            <ul
              className="linkedin-post-body-img-container"
              ref={this.containerRef}
            >
              {this.posts}
            </ul>
          </div>
        </div>
      </a>
    );
  }
}

export default LinkedInPost;
